import * as React from "react";
import { StoreProvider } from "./src/context/store-context";

import "@fortawesome/fontawesome-pro";
import "@fontsource/montserrat";
import "@fontsource/montserrat/400-italic.css";
import "@fontsource/montserrat/600.css";
import "@fontsource/merriweather";
import "@fontsource/merriweather/300.css";
import "@fontsource/merriweather/700.css";
import "@fontsource/bebas-neue";
import "./src/css/index.css";

export const wrapRootElement = ({ element }) => (
  <StoreProvider>{element}</StoreProvider>
);

export function shouldUpdateScroll({
  routerProps: { location },
  getSavedScrollPosition,
}) {
  const { pathname } = location
  // list of routes for the scroll-to-top-hook
  const scrollToTopRoutes = [`/cx-insights-hub/`]
  // if the new route is part of the list above, scroll to top (0, 0)
  if (scrollToTopRoutes.indexOf(pathname) !== -1) {
    return false
  }
}